$main-toolbar-color: #333;
$btn-shadow: #eae7e7;
$primary-dark-blue: #005b7c;
$primary-light-blue: #00aeed;

$primary-blue: #1a73e8;

$primary-blue-dark: #00517d;
$primary-blue-light: #28bfef;
$primary-red: #b2291f;
$grey-blue: #e6eef2;
$primary-grey: #cdcece;
$drak-grey: #a1a1a1;

//NEW
$primary-color-bg: #f2fbff;
$secondary-color-bg: #e4f2fa;
$main-light-blue: #4285f4;
$main-dark-blue: #195cc0;
$white: #fff;
$black: #000;
$btns-color: #1b5ec3;

$font-black-color: #212529;

$phase_blue_1: #4285f4;
$phase_blue_2: #048bb6;
$phase_green: #02954e;
$phase_yellow: #e8a801;
$phase_red: #c3391b;

$border_grey:#D9D6D6;
$fab-color: #1b5ec3