@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./shared/colors.scss";
@import "./shared/fonts.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
/**EDITOR **/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: $ProductSans !important;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  background-color: $primary-color-bg !important;
}

.product-sans-thin {
  font-family: $ProductSansThin;
  font-weight: normal;
}
.product-sans-light {
  font-family: $ProductSansLight;
  font-weight: normal;
}
.product-sans {
  font-family: $ProductSans;
  font-weight: normal;
}
.product-sans-medium {
  font-family: $ProductSansMedium;
  font-weight: normal;
}
.product-sans-bold {
  font-family: $ProductSansBold;
  font-weight: normal;
}

.main-bg {
  height: 100vh;
  background: linear-gradient(
      180deg,
      $secondary-color-bg 0%,
      $primary-color-bg 100%
    )
    0% 0% repeat-y padding-box;
}
.box-shadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
.fluid-section {
  overflow-y: scroll;
  // max-height: 80vh;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.fluid-section::-webkit-scrollbar {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}
.hidden-text {
  visibility: hidden;
}
.btn-color {
  background: $btns-color !important;
  border: 0;
  color: white;
}
.blue-text {
  color: #1b5ec3 !important;
}
.btn-color:hover {
  opacity: 0.85 !important;
}
.btn-bg-white {
  background: white !important;
  border: 0;
  color: black;
}
.btn-bg-white:hover,
.btn-bg-white:focus {
  opacity: 0.85 !important;
  color: black !important;
}
.btn-color-outline {
  background: transparent !important;
  border: $btns-color solid 1px;
  color: $btns-color;
}
.btn-color-outline:hover {
  background: $btns-color !important;
  border: 0;
  color: #fff;
}
.smaller-text {
  font-size: 0.9rem !important;
}
.small-text {
  font-size: 1rem !important;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-20 {
  font-size: 20px;
}
.f_black {
  color: black;
}
input[type="number"] {
  background-size: 15px;
  background-image: none;
}

input[type="number"]::-webkit-inner-spin-button {
  width: 0px;
}
@media (max-width: 576px) {
  .smaller-text {
    font-size: 0.7rem !important;
  }
  .small-text {
    font-size: 0.85rem !important;
  }
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.m-t-m-25 {
  margin-top: -25px;
}
.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.w-50-px {
  width: 50px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}
.m-l-75 {
  margin-left: 75px !important;
}
.m-l-85 {
  margin-left: 85px !important;
}

.p-4px {
  padding: 4px !important;
}
.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}

////
.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-r-l-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-t-b-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-t-b-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-5-px {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-15 {
  margin: 15px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-28 {
  margin-right: 28px !important;
}

.dis-block {
  display: block !important;
}
.dis-content {
  display: contents !important;
}

.w-0 {
  width: 0% !important;
}

.w-14 {
  width: 14% !important;
}

.w-100 {
  width: 100% !important;
}
.w-30-px {
  width: 30px !important;
}

.h-100 {
  height: 100% !important;
}
.h-36 {
  height: 36px !important;
}
.h-40 {
  height: 40px !important;
}

.h-55 {
  height: 55px !important;
}

.h-95 {
  height: 95% !important;
}
.w-h-25 {
  width: 25px;
  height: 25px;
}
.min-h-200 {
  min-height: 200px !important;
}
.max-w-95 {
  max-width: 95% !important;
}
.h-fit-content {
  height: fit-content !important;
}
.w-fit-content {
  width: fit-content !important;
}

.txt-end {
  text-align: end !important;
}

.cursor_none {
  cursor: none !important;
  pointer-events: none;
}
.pointer-events-none {
  pointer-events: none;
}
.border-raduis-16 {
  border-radius: 16px !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.color_red {
  color: $primary-red;
  font-weight: bold;
}

.color_green {
  color: green;
  font-weight: bold;
}
.z-9 {
  z-index: 9 !important;
}
.z-10 {
  z-index: 10 !important;
}

.bg-white.box-shadow {
  height: 94vh !important;
}

/**** DATATABLE ****/
.ngx-datatable .datatable-footer .datatable-footer-inner {
  background-color: white !important;
}
.datatable_header {
  font-family: $ProductSansMedium;
  font-weight: normal;
  font-size: 16px;
}
.datatable_cell {
  font-family: $ProductSansLight;
  font-weight: normal;
  font-size: 16px;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  background-color: white !important;
  border-bottom: 1px solid !important;
  border-top: none !important;
  border-color: rgb(202, 201, 201) !important;
  // width: 100% !important;
}
.ngx-datatable.bootstrap .datatable-header {
  border-bottom: 1px solid #413e3e !important;
}

// .ngx-datatable .datatable-footer {
//   position: absolute;
//   top: 0;
// }
.ngx-datatable.bootstrap .datatable-footer .page-count {
  font-family: $ProductSans;
  font-weight: normal;
  font-size: 16px;
  text-align: right !important;
  color: #212529 !important;
}
.ngx-datatable .datatable-footer .datatable-pager {
  flex: none !important;
  text-align: right !important;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  color: $main-dark-blue !important;
}
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: $main-dark-blue !important;
  color: white !important;
}
// DATATABLE WIDTH ISSUE
datatable-scroller {
  width: 100% !important;
}
.ngx-datatable .datatable-header-inner {
  width: 100% !important;
}
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-header-inner .datatable-row-center {
  min-width: 100% !important;
}

// .ngx-datatable.fixed-row .datatable-body-row {
//   width: 100% !important;
// }
// .ngx-datatable .datatable-row-center,
// .ngx-datatable .datatable-row-group,
// .ngx-datatable .datatable-row-left,
// .ngx-datatable .datatable-row-right {
//   width: 100% !important;
// }

.ngx-datatable.bootstrap .datatable-body,
.ngx-datatable.bootstrap .datatable-header {
  datatable-body-row {
    width: 100% !important;
    min-width: 1350px !important;
    max-width: 100% !important;
  }
}
//
/****/
.ng-select .ng-select-container {
  border-radius: 800px !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}
.is-invalid {
  border-color: red !important;
}
.ng-select.is-invalid {
  .ng-select-container {
    border-color: red !important;
  }
}

/** BACK HEADER PAGE ICON ***/
img[alt="view icon"],
img[alt="edit icon"],
img[alt="delete icon"],
img[alt="back icon"],
img[alt="next icon"],
img[alt="expand icon"] {
  width: 40px;
  height: 40px;
  padding: 0.7rem;
}
img[alt="view icon"]:hover:not(img[alt="expand icon"]),
img[alt="edit icon"]:hover:not(img[alt="expand icon"]),
img[alt="delete icon"]:hover:not(img[alt="expand icon"]),
img[alt="back icon"]:hover:not(img[alt="expand icon"]),
img[alt="next icon"]:hover:not(img[alt="expand icon"]),
img[alt="expand icon"]:hover:not(img[alt="expand icon"]) {
  background: rgba(25, 92, 192, 0.3490196078);
  border-radius: 50%;
}
/***/
.border-raduis-xl-1rem {
  border-radius: 1rem !important;
}
@media (max-width: 1199px) {
  .mobile-section {
    overflow-y: scroll;
  }
}
input[type="date"] {
  height: 33px !important;
}

.orders-switch {
  background-image: url("./assets/img/gws-orders-switch.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 12rem !important;
  height: 2rem;
  transition: background-image 0.2s ease-in-out;
}

.orders-switch.toggled {
  background-image: url("./assets/img/gcp-orders-switch.svg");
}

.form-control.ng-select {
  border: none;
  padding: 0;
}
ng-select {
  div[role="combobox"] input {
    border: none !important;
  }
}

.scroll_y {
  overflow-y: scroll !important;
}
/*** DROPDOWN ***/
.sidebar-narrowed .dropdown-menu.show {
  background: #1b5ec3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 0;
  font-family: Product Sans Light, serif;
  inset: auto auto -140% 20% !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #000 !important;
}

.dropend .dropdown-toggle::after {
  border-left-color: #fff;
  margin-left: -1rem;
  visibility: hidden;
}

.dropdown-toggle:focus::after {
  visibility: visible;
}
.dropdown-toggle.btn-color::after {
  vertical-align: 0 !important;
  border: 0 !important;
  background-image: url("./assets/img/arrow-icon.svg");
  background-position: center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  width: 15%;
  height: 90%;
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(-50%, -45%);
  border-left: 1px solid #fff !important;
}

.dropdown-menu.show {
  inset: auto auto 0px -4rem !important;
}
input[type="text"].my_disabled_input,
input[type="password"].my_disabled_input,
input[type="email"].my_disabled_input,
input[type="number"].my_disabled_input,
input[type="date"].my_disabled_input,
input[type="url"].my_disabled_input,
input[type="tel"].my_disabled_input {
  background-color: #e9ecef !important;
  pointer-events: none !important;
}
vinput[type="url"].disabled-domain {
  flex: 1;
  border: none !important;
}
.ng-select.my_disabled_input {
  pointer-events: none !important;
  .ng-select-container {
    background-color: #e9ecef !important;
    pointer-events: none !important;
  }
}
input[type="radio"].my_disabled_input {
  pointer-events: none !important;
}
// footer {
//   bottom: 13px;
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   height: 45px;
//   width: 100%;
// }

img[alt="view icon"],
img[alt="edit icon"],
img[alt="delete icon"],
img[alt="manage members icon"],
img[alt="back icon"],
img[alt="next icon"],
img[alt="renew icon"],
img[alt="paid service icon"],
img[alt="suspend icon"],
img[alt="activate icon"],
img[alt="cancel icon"],
img[alt="upgrade icon"],
img[alt="downgrade icon"],
img[alt="renewal settings icon"],
img[alt="expand icon"] {
  width: 40px;
  height: 40px;
  padding: 0.7rem;
  &:hover:not(img[alt="expand icon"]) {
    background: #195cc059;
    border-radius: 50%;
  }
}

img[alt="renew icon"],
img[alt="paid service icon"],
img[alt="suspend icon"],
img[alt="activate icon"],
img[alt="cancel icon"],
img[alt="upgrade icon"],
img[alt="downgrade icon"],
img[alt="renewal settings icon"],
img[alt="expand icon"] {
  width: 30px;
  height: 30px;
  padding: 0.4rem;
  &:hover:not(img[alt="expand icon"]) {
    background: #195cc059;
    border-radius: 50%;
  }
}

@media (max-width: 768px) {
  img[alt="next icon"],
  img[alt="renew icon"],
  img[alt="paid service icon"],
  img[alt="suspend icon"],
  img[alt="activate icon"],
  img[alt="cancel icon"],
  img[alt="upgrade icon"],
  img[alt="downgrade icon"],
  img[alt="renewal settings icon"],
  img[alt="expand icon"] {
    width: 20px;
    height: 20px;
    padding: 0.2rem;
  }

  .sku-pricing .text-nowrap,
  .permissions .text-nowrap {
    white-space: inherit !important;
  }
}

@media (max-width: 576px) {
  img[alt="view icon"],
  img[alt="edit icon"],
  img[alt="delete icon"],
  img[alt="manage members icon"],
  img[alt="back icon"] {
    width: 20px;
    height: 20px;
    padding: 0.3rem;
  }

  img[alt="next icon"],
  img[alt="renew icon"],
  img[alt="paid service icon"],
  img[alt="suspend icon"],
  img[alt="activate icon"],
  img[alt="cancel icon"],
  img[alt="upgrade icon"],
  img[alt="downgrade icon"],
  img[alt="renewal settings icon"],
  img[alt="expand icon"] {
    width: 15px;
    height: 15px;
    padding: 0.1rem;
  }

  .back-icon {
    margin-left: -1rem;
    padding: 0.2rem !important;
  }

  .box-shadow.bg-white.rounded-5 {
    border-radius: 1rem !important;
  }

  .smaller-text {
    font-size: 0.7rem !important;
  }

  .small-text {
    font-size: 0.85rem !important;
  }

  input[type="number"] {
    background-size: 15px;
    background-image: none;
  }

  input[type="number"]::-webkit-inner-spin-button {
    width: 0px;
  }

  .sku-pricing.fluid-section,
  .permissions.fluid-section {
    max-height: 65vh;
  }
}
.max-h-55 {
  max-height: 55vh;
}
.max-h-80 {
  max-height: 80vh;
}
.max-h-70 {
  max-height: 70vh;
}
.max-h-75 {
  max-height: 75vh !important;
}
.max-h-93 {
  max-height: 93vh;
}

.table-responsive.fluid-section,
.collapsible.fluid-section {
  max-height: 80vh;
}
//
.all-table .table-responsive.fluid-section,
.pending-table .table-responsive.fluid-section,
.sku-table,
.currency-pricing.fluid-section,
.exchange-rate.fluid-section,
.groups.fluid-section,
.sku-pricing.fluid-section,
.permissions.fluid-section,
.customers-table .table-responsive.fluid-section {
  height: 62vh;
}
#orders-renewal .table-responsive.fluid-section,
.collapsible.fluid-section {
  max-height: 45vh;
}

#orders-renewal .table-responsive.fluid-section,
#orders-flex .table-responsive.fluid-section {
  max-height: 60vh;
}
.subscriptions.fluid-section,
.orders.fluid-section,
.deals.fluid-section,
.orders-table .table-responsive.fluid-section {
  max-height: 45vh;
}
//
.btn_sku_toggle {
  border: none !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0.7rem !important;
}
.btn_sku_toggle:hover,
.btn_sku_toggle:focus,
.btn_sku_delete:hover,
.btn_sku_delete:focus {
  background: rgba(25, 92, 192, 0.3490196078);
  border-radius: 50%;
}
.expand-icon,
.accordion-button::after {
  background-image: url("./assets/img/expand-icon.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 0.8rem;
  height: 1.2rem;
  transition: background-image 0.3s ease-in-out;
  margin-left: 0;
}

.expand-icon.transited,
.accordion-button:not(.collapsed)::after {
  background-image: url("./assets/img/expanded-icon.svg");
}

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";
// NEW AFTER UPFATE ANGULAR 16
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
.table > :not(:first-child) {
  border-top: 2px solid grey !important;
}
.ng-dropdown-panel {
  z-index: 99999 !important;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(2.5rem + calc(var(--bs-border-width) * 2)) !important;
  min-height: calc(2.5rem + calc(var(--bs-border-width) * 2)) !important;
  line-height: 1.5 !important;
}

.form-floating > label {
  height: 80% !important;
  padding: 0.5rem 0.75rem !important;
}

.form-floating > .form-select ~ label {
  font-size: 0.875rem !important;
}
.form-floating > .form-select {
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}

.w-165 {
  width: 165px !important;
}
@media (max-width: 576px) {
  .w-165 {
    width: auto !important;
  }
}
.usr_img {
  width: 36px;
  height: 36px;
}
.rotate-90 {
  transform: rotate(90deg);
}
.blue_1 {
  color: $phase_blue_1;
}
.label-ellipsis {
  // white-space: nowrap; /* Prevent text wrapping */
  // overflow: hidden; /* Hide excess text */
  // text-overflow: ellipsis; /* Add ellipsis */
  // width: 220px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.label_grey_border_bottom {
  border-bottom: 1px solid #d9d9d9;
}

.btn_add_row {
  border: none !important;
  background: none !important;
  color: $btns-color;
  img {
    width: 10px;
    height: 10px;
  }
  span {
    font-size: 13px;
    padding-left: 1px;
  }
}
.btn_add_row:hover,
.btn_add_row:focus {
  color: $phase_blue_1;
}

.popup_all_width {
  min-width: 100% !important;
  padding: 15px;
}
.flex_auto {
  flex: auto !important;
}
.divider_label {
  border-left: 1px solid #898989;
  padding: 7px 12px;
}
.popup_header {
  background-color: $phase_blue_1 !important;
  color: white !important;
}
.img-user {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
@media screen and (max-width: 600px) {
  .img-user {
    width: 35px;
    height: 35px;
  }
}
.input_border {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}
.d_contents {
  display: contents !important;
}
.my_disabled {
  opacity: 0.2;
  pointer-events: none;
}
.popup_shadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.page_content_main {
  overflow-y: scroll;
  height: 93vh;
  overflow-x: hidden;
}
.page_content_main::-webkit-scrollbar {
  display: none;
}

.phone_disabled  .iti--allow-dropdown input, .phone_disabled  .iti--allow-dropdown input[type=text],.phone_disabled  .iti--allow-dropdown input[type=tel],.phone_disabled  .iti--separate-dial-code input, .phone_disabled  .iti--separate-dial-code input[type=text],.phone_disabled  .iti--separate-dial-code input[type=tel]{
  background-color: #e9ecef !important;
  pointer-events: none !important;
}

.iti--allow-dropdown{
  width: 100% !important;
}

ngx-intl-tel-input.is-invalid input{
  border-color:red !important;
}
ngx-intl-tel-input {
  width: 100% !important;
}

ngx-intl-tel-input input{
  width:100% !important;
}

/* Style Flat */
.switch.flat .slider {
  box-shadow: none;
}
.switch.flat .slider:before {
  background: #fff;
}
.switch.flat input:checked + .slider:before {
  background: white;
}
.switch.flat input:checked + .slider {
  background: black;
  color: black;
}
.btn_collapse_parent {
  border: 1px solid $phase_blue_1;
  border-radius: 12px;
  font-size: 12px;
  .btn_collapse_header {
    border: none;
    border-radius: 12px;
    text-align: start;
    padding: 0 !important;
    background-color: white;
    color: black;
  }
  .btn_collapse_content {
    padding: 3px;
    background-color: #ccf3ff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
.check_green {
  color: #57c174;
}

.btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233656B3'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") !important;
  opacity: 1 !important;
}
.custom-bg-blue {
  background-color: #1b5ec3;
}
.custom-bg-white {
  background-color: white;
}
.help-btn {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.75) !important;
  border-radius: 0;
}
.user-icon {
  height: 25px;
}
.user-icon:hover {
  box-shadow: 0px 0px 6px #fff;
}
.new-customer-icon {
  background-image: url("./assets/img/new-customer-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
  transition: background 0.25s ease-out;
  &:hover {
    background-image: url("./assets/img/new-customer-icon-hover.png");
  }
  &:active {
    background-image: url("./assets/img/new-customer-icon-active.png");
  }
}

.transfer-customer-icon {
  background-image: url("./assets/img/transfer-customer-icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
  transition: background 0.25s ease-out;
  &:hover {
    background-image: url("./assets/img/transfer-customer-icon-hover.png");
  }
  &:active {
    background-image: url("./assets/img/transfer-customer-icon-active.png");
  }
}

@media (max-width: 768px) {
  .new-customer-icon,
  .transfer-customer-icon {
    height: 150px;
    width: 150px;
  }
}
.might-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.round {
  border-radius: 50%;
}

/**OPP**/
#opp_details_page {
  #div_setps {
    margin: 0;
    padding: 0;
    height: 47vh;
    // margin-left: 1%;
    margin: 4px !important;
  }
  .opp_step {
    //width: 19.6%;
    margin-right: 4px !important;
    margin-top: 4px !important;
  }
  @media (min-width: 1200px) {
    .opp_step {
      width: 19.6%;
    }
  }
  @media (min-width: 950px) and (max-width: 1199px) {
    .opp_step {
      width: 19.4%;
    }
  }
  @media (min-width: 500px) and (max-width: 949px) {
    .opp_step {
      width: 30%;
    }
  }
  .opp_deal_step_new {
    border-radius: 40px 4px 0px 20px;
    background-color: $phase_blue_1;
  }
  .txt_deal_phase {
    color: white;
  }
  .icon_step {
    width: 65px;
  }
  .div_skip {
    color: white;
  }
  .switch input {
    display: none;
  }
  .switch {
    display: inline-block;
    width: 30px;
    height: 15px;
    margin: 8px;
    transform: translateY(50%);
    position: relative;
  }
  .opp_col_content {
    padding: 5px 13px !important;
  }

  .opp_col_content .phase-content {
    flex: 9;
  }
  .opp_col_content .time-content {
    justify-content: end;
    margin-bottom: 3px;
    flex: 0;
  }
  .switch.flat .slider {
    box-shadow: none;
  }
  .switch.flat .slider:before {
    background: #fff;
  }
  .switch.flat input:checked + .slider:before {
    background: white;
  }
  .switch.flat input:checked + .slider {
    background: black;
    color: black;
  }
  .deal_step_header {
    height: 13%;
    padding: 8px 0;
    color: white;
    padding-left: 15px;
    border-radius: 40px 4px 0px 0px;
    background: $phase_blue_1;
  }
  .deal_step_content {
    border: 1px solid $phase_blue_1;
    height: 87%;
    border-radius: 0px 0px 0px 20px;
    padding: 0 10px;
    overflow-y: scroll;
  }
  .deal_step_content::-webkit-scrollbar {
    display: none;
  }
  .deal_step_content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .btn_collapse_parent {
    border: 1px solid $phase_blue_1;
    border-radius: 12px;
    font-size: 12px;
    .btn_collapse_header {
      border: none;
      border-radius: 12px;
      text-align: start;
      padding: 0 !important;
      background-color: white;
      color: black;
    }
    .btn_collapse_content {
      padding: 3px;
      background-color: #ccf3ff;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  .opp_status_inprogress {
    background-color: #4285f4;
    padding: 3px 10px;
    color: white;
    text-transform: capitalize;
  }
  .btn_view_customer {
    padding: 8px 0;
    margin: 0;
  }
  .ic_time {
    padding: 6px 8px 6px 0px;
  }
  .phase_details_title {
    color: #000;
    font-family: Product Sans Light;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }
  .phase_details_data_txt {
    color: #000;
    font-family: Product Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .phases_disabled_btn {
    opacity: 0.2;
    pointer-events: none;
  }
  .step_skipped {
    background-color: #e1e1e1 !important;
  }
  .picked {
    border: 3px solid #1b5ec3;
    border-radius: 10px;
  }
  .img_picked_engineer {
    height: 96px !important;
    width: 96px !important;
  }
  .services-row {
    background-color: #e9ecef;

    .input-group .mdc-checkbox__background {
      border-color: #1b5ec3 !important;
    }
    .mdc-checkbox__checkmark {
      color: #57c174 !important;
    }
    .mdc-checkbox__background {
      background: white !important;
    }
  }
  .services-delivered {
    background-color: #d3eaff !important;
    border: 3px solid#1B5EC3 !important;
    border-radius: 10px;
  }
  .progress-text {
    color: #000;
    font-weight: 700;
    position: absolute;
    display: block;
    right: 5%;
  }
  .approved-img {
    width: 60px;
    height: auto;
  }
  .step_count {
    margin-top: 30%;
    text-align: center;
  }
}
