@font-face {
  font-family: "Product Sans Thin";
  src: url(../assets/fonts/FONTSFREE-NET-PRODUCTSANS-THIN.TTF)
    format("truetype");
  font-weight: 100;
  font-size: 16px !important;
  font-display: swap;
}
@font-face {
  font-family: "Product Sans Light";
  src: url(../assets/fonts/FONTSFREE-NET-PRODUCTSANS-LIGHT.TTF)
    format("truetype");
  font-weight: 100;
  font-size: 16px !important;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url(../assets/fonts/FONTSFREE-NET-PRODUCTSANS-REGULAR.TTF)
    format("truetype");
  font-weight: 600;
  font-size: 16px !important;
  font-display: swap;
}
@font-face {
  font-family: "Product Sans Medium";
  src: url(../assets/fonts/FONTSFREE-NET-PRODUCTSANS-MEDIUM.TTF)
    format("truetype");
  font-weight: 600;
  font-size: 16px !important;
  font-display: swap;
}
@font-face {
  font-family: "Product Sans Bold";
  src: url(../assets/fonts/FONTSFREE-NET-PRODUCTSANS-BLACK.TTF)
    format("truetype");
  font-weight: 800;
  font-size: 16px !important;
  font-display: swap;
}
$ProductSansThin: Product Sans Thin;
$ProductSansLight: Product Sans Light;
$ProductSans: Product Sans;
$ProductSansMedium: Product Sans Medium;
$ProductSansBold: Product Sans Bold;
